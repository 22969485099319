import React, { useState } from "react";

function MainWindowView({ item }) {
  const [isExpanded, setIsExpanded] = useState(false);

  if (!item)
    return <div className="main-window">Select an item to view details</div>;

  return (
    <div className="main-window">
      <div className="level-block" onClick={() => setIsExpanded(!isExpanded)}>
        <h3>Function Name: {item.abi.name}</h3>
        {isExpanded && (
          <div className="function-inputs-outputs">
            {item.abi.inputs && (
              <div className="function-inputs">
                <h4>Function Inputs:</h4>
                <ul>
                  {item.abi.inputs.map((input, index) => (
                    <li key={index}>
                      <strong>Name:</strong> {input.name} <br />
                      <strong>Type:</strong> {input.internalType} <br />
                      {typeof input.indexed !== "undefined" && (
                        <span>
                          {input.indexed ? (
                            <span style={{ color: "green" }}>✔️ Indexed</span>
                          ) : (
                            <span style={{ color: "red" }}>❌ Indexed</span>
                          )}
                        </span>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            )}
            {item.abi.outputs && (
              <div className="function-outputs">
                <h4>Function Outputs:</h4>
                <ul>
                  {item.abi.outputs.map((output, index) => (
                    <li key={index}>
                      <strong>Name:</strong> {output.name} <br />
                      <strong>Type:</strong> {output.internalType}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default MainWindowView;
