import React, { useState } from "react";

function TransactionFeedView({ latestTransactions }) {
  const [expandedTransaction, setExpandedTransaction] = useState(null);

  const toggleExpand = (transactionHash) => {
    if (expandedTransaction === transactionHash) {
      setExpandedTransaction(null);
    } else {
      setExpandedTransaction(transactionHash);
    }
  };

  return (
    <div className="transaction-feed">
      <h2>Latest Transactions</h2>
      {latestTransactions.length > 0 ? (
        <ul>
          {latestTransactions.map((transaction) => (
            <li key={transaction.transaction_hash} className="transaction-item">
              <div onClick={() => toggleExpand(transaction.transaction_hash)}>
                <p>Transaction Hash: {transaction.transaction_hash}</p>
              </div>
              {expandedTransaction === transaction.transaction_hash && (
                <div className="transaction-item-expanded">
                  <p>Chain: {transaction.chain}</p>
                  <p>Block Number: {transaction.block_number}</p>
                  <p>Block Hash: {transaction.block_hash}</p>
                  <p>Transaction Index: {transaction.transaction_index}</p>
                  <p>Log Index: {transaction.log_index}</p>
                  <p>Address: {transaction.address}</p>
                </div>
              )}
            </li>
          ))}
        </ul>
      ) : (
        <div className="no-transactions-message">
          No transactions found filtered by the input value in the last 100
          blocks.
        </div>
      )}
    </div>
  );
}

export default TransactionFeedView;
