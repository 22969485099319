import React, { useState, useEffect } from "react";
import axios from "axios";
import "./App.css";
import HierarchicalListView from "./views/HierarchicalListView";
import MainWindowView from "./views/MainWindowView";
import TransactionFeedView from "./views/TransactionFeedView";

function App() {
  const [selectedItem, setSelectedItem] = useState(null);
  const [searchResults, setSearchResults] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [submittedValue, setSubmittedValue] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [latestTransactions, setLatestTransactions] = useState([]);

  const fetchLatestTransactions = async (query) => {
    setLatestTransactions([]);
    if (!query) return;

    try {
      const response = await axios.get(
        `/api/transactions?searchString=${query}`
      );

      if (response.data) {
        let parsedObjects;

        // Attempt to parse the entire response data as a single JSON object
        try {
          const singleJsonObject = JSON.parse(response.data);
          parsedObjects = [singleJsonObject];
        } catch (e) {
          // If the above throws an error, try to parse as multiple JSON objects
          const jsonLines = response.data.split("\n");
          const nonEmptyLines = jsonLines.filter((line) => line.trim() !== "");
          parsedObjects = nonEmptyLines.map((line) => JSON.parse(line));
        }

        setLatestTransactions(parsedObjects);
      }
    } catch (error) {
      console.error("Error fetching transactions:", error);
    }
  };

  useEffect(() => {
    let intervalId;

    if (submittedValue) {
      fetchLatestTransactions(submittedValue);

      intervalId = setInterval(() => {
        fetchLatestTransactions(submittedValue);
      }, 30000); // 30 seconds
    }

    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [submittedValue]);

  const handleSearch = async (query) => {
    try {
      const response = await axios.post(
        "/api/search",
        { query },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data && response.data.length > 0) {
        setSearchResults(response.data);
        setErrorMessage(null);
      } else {
        setSearchResults([]);
        setErrorMessage("No data found for the required input.");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setSearchResults([]);
      setErrorMessage("An error occurred while fetching data.");
    }
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    handleSearch(inputValue);
    setSubmittedValue(inputValue);
  };

  return (
    <div className="app-container">
      <form onSubmit={handleFormSubmit}>
        <input
          type="text"
          placeholder="Search..."
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
        />
      </form>
      <div className="content-container">
        {errorMessage ? (
          <div className="error-message">{errorMessage}</div>
        ) : (
          <>
            <HierarchicalListView
              onItemSelect={setSelectedItem}
              data={searchResults}
            />
            <MainWindowView item={selectedItem} />
            <TransactionFeedView latestTransactions={latestTransactions} />
          </>
        )}
      </div>
    </div>
  );
}

export default App;
