import React, { useState } from "react";

function HierarchicalItem({ item, onItemSelect }) {
  const [expanded, setExpanded] = useState({ address: false, function: false });

  const handleAddressClick = () => {
    setExpanded({ ...expanded, address: !expanded.address });
  };

  const handleFunctionClick = () => {
    onItemSelect(item);
    setExpanded({ ...expanded, function: !expanded.function });
  };

  return (
    <div>
      <p onClick={handleAddressClick}>
        <strong>Contract Address:</strong> {item.metas[0].contract_address}
      </p>

      {expanded.address && (
        <div style={{ marginLeft: "20px" }}>
          <p onClick={handleFunctionClick}>
            <strong>Function Name:</strong> {item.abi.name}
          </p>

          {/* {expanded.function && (
            <div style={{ marginLeft: "20px" }}>
              <p>
                <strong>Event Topic 0:</strong> {item.metas[0].sig}
              </p>
            </div>
          )} */}
        </div>
      )}
    </div>
  );
}

function HierarchicalListView({ onItemSelect, data }) {
  return (
    <div className="list-view">
      {data.map((item, index) => (
        <HierarchicalItem key={index} item={item} onItemSelect={onItemSelect} />
      ))}
    </div>
  );
}

export default HierarchicalListView;
